import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Header/Navbar";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./ScrollToTop";
import Training from "./Pages/Training/Training";
import Contact from "./Pages/Contact/Contact";
import PPL from "./Pages/Training/Pilot Training/PPL";
import CPL from "./Pages/Training/Pilot Training/CPL";
import TypeRating from "./Pages/Training/Pilot Training/TypeRating";
import AboutUs from "./Pages/About Us/AboutUs";
import OurGovernance from "./Pages/About Us/Pages/OurGovernance";
import OurValues from "./Pages/About Us/Pages/OurValues";
import OurPresence from "./Pages/About Us/Pages/OurPresence";
import Colleges from "./Pages/Colleges/Colleges";
import BbaAviation from "./Pages/Training/Degree Programs/BbaAviation";
import BscAviation from "./Pages/Training/Degree Programs/BscAviation";
import BscAeroScience from "./Pages/Training/Degree Programs/BscAeroScience";
import MbaAviation from "./Pages/Training/Degree Programs/MbaAviation";
import MbaHRFinance from "./Pages/Training/Degree Programs/MbaHRFinance";
import CabinCrew from "./Pages/Training/Diploma Programs/CabinCrew";
import PgDiplomaAME from "./Pages/Training/Diploma Programs/PgDiplomaAME";
import DiplomaInAirportManagement from "./Pages/Training/Diploma Programs/DiplomaInAirportManagement";
import ATPL from "./Pages/Training/Pilot Training/ATPL";
import LabEstablishment from "./Pages/Consulting/LabEstablishment";
import ACMI from "./Pages/Consulting/ACMI";
import DGCALC from "./Pages/Training/Pilot Training/DGCALC";
import DGCAMR from "./Pages/Training/Pilot Training/DGCAMR";
import DGCACN from "./Pages/Training/Pilot Training/DGCACN";
import BscAME from "./Pages/Training/Degree Programs/BscAME";
import Agriculture from "./Pages/Agriculture/Agriculture";
import FlightSimulator from "./Pages/Innovation/FlightSimulator";
import Cessna from "./Pages/Flight Simulator/Cessna";
import Airbus from "./Pages/Flight Simulator/Airbus";
import Boeing from "./Pages/Flight Simulator/Boeing";
import Gallery from "./Pages/Gallery/Gallery";
import Pilot from "./Pages/Career/Pilot";
import AME from "./Pages/Career/AME";
import CabinCrewInstructor from "./Pages/Career/CabinCrewInstructor";
import GroundStaff from "./Pages/Career/GroundStaff";
import FlightDispatch from "./Pages/Career/FlightDispatch";
import IT from "./Pages/Career/IT";
import DronePilot from "./Pages/Career/DronePilot";
import BDM from "./Pages/Career/BDM";
import Finance from "./Pages/Career/Finance";
import Media from "./Pages/Career/Media";
import Blogs from "./Pages/Blogs/Blogs";
import Blog1 from "./Pages/Blogs/Pages/Blog_1";
import Blog2 from "./Pages/Blogs/Pages/Blog_2";
import Blog3 from "./Pages/Blogs/Pages/Blog_3";
import Blog4 from "./Pages/Blogs/Pages/Blog_4";
import Blog5 from "./Pages/Blogs/Pages/Blog_5";
import Blog6 from "./Pages/Blogs/Pages/Blog_6";
import Campus from "./Pages/Students/Campus";
import Blog7 from "./Pages/Blogs/Pages/Blog_7";
import Blog8 from "./Pages/Blogs/Pages/Blog_8";
import Blog9 from "./Pages/Blogs/Pages/Blog_9";
import Blog10 from "./Pages/Blogs/Pages/Blog_10";
import Blog11 from "./Pages/Blogs/Pages/Blog_11";
import Blog12 from "./Pages/Blogs/Pages/Blog_12";
import Blog13 from "./Pages/Blogs/Pages/Blog_13";
import VelsLanding from "./Pages/Colleges/Pages/VelsLanding";
import CrescentLanding from "./Pages/Colleges/Pages/CrescentLanding";
import JamalLanding from "./Pages/Colleges/Pages/JamalLanding";
import FixedWingElectricAircraft from "./Pages/Innovation/FixedWingElectricAircraft";
import Blog14 from "./Pages/Blogs/Pages/Blog_14";
import Blog15 from "./Pages/Blogs/Pages/Blog_15";
import Blog16 from "./Pages/Blogs/Pages/Blog_16";
import Blog17 from "./Pages/Blogs/Pages/Blog_17";
import Blog18 from "./Pages/Blogs/Pages/Blog_18";
import Blog19 from "./Pages/Blogs/Pages/Blog_19";
import Blog20 from "./Pages/Blogs/Pages/Blog_20";
import Blog21 from "./Pages/Blogs/Pages/Blog_21";
import Blog22 from "./Pages/Blogs/Pages/Blog_22";
import Blog23 from "./Pages/Blogs/Pages/Blog_23";
import Blog24 from "./Pages/Blogs/Pages/Blog_24";
import Blog25 from "./Pages/Blogs/Pages/Blog_25";
import PrivacyPolicy from "./Pages/Privacy Policy/PrivacyPolicy";
import TermsAndConditions from "./Pages/Terms and conditions/TermsAndConditions";
import DGCAStudents from "./Pages/Students/DGCAStudents";
import { FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";

function App() {
  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

  return (
    <div className="scroll-smooth overflow-hidden">
      <Navbar setBackgroundBlur={setIsBackgroundBlurred} />
      <div id="background" className={`xl:pt-12 max-sm:pt-12 ${isBackgroundBlurred ? 'blur-sm' : ''}`}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/training"
            element={<Training />}
          />
          <Route
            path="/private-pilot-license"
            element={<PPL />}
          />
          <Route
            path="/commercial-pilot-license"
            element={<CPL />}
          />
          <Route
            path="/typerating"
            element={<TypeRating />}
          />
          <Route
            path="/bba-aviation"
            element={<BbaAviation />}
          />
          <Route
            path="/bsc-aviation"
            element={<BscAviation />}
          />
          <Route
            path="/bsc-aeronautical-science"
            element={<BscAeroScience />}
          />
          <Route
            path="/bsc-aircraft-maintenance-science"
            element={<BscAME />}
          />
          <Route
            path="/mba-aviation-management"
            element={<MbaAviation />}
          />
          <Route
            path="/mba-hr-marketing-and-finance"
            element={<MbaHRFinance />}
          />
          <Route
            path="/cabincrew"
            element={<CabinCrew />}
          />
          <Route
            path="/PG-Diploma-in-AME"
            element={<PgDiplomaAME />}
          />
          <Route
            path="/diploma-in-airport-management"
            element={<DiplomaInAirportManagement />}
          />
          <Route
            path="/airline-transport-pilot-license"
            element={<ATPL />}
          />
          <Route
            path="/dgca-license-conversion"
            element={<DGCALC />}
          />
          <Route
            path="/dgca-medical-requirements"
            element={<DGCAMR />}
          />
          <Route
            path="/dgca-pilot-computer-number"
            element={<DGCACN />}
          />
          <Route
            path="/contact"
            element={<Contact />}
          />
          <Route
            path="/company"
            element={<AboutUs />}
          />
          <Route
            path="/ourgovernance"
            element={<OurGovernance />}
          />
          <Route
            path="/our-values"
            element={<OurValues />}
          />
          <Route
            path="/our-presence"
            element={<OurPresence />}
          />
          <Route
            path="/colleges"
            element={<Colleges />}
          />
          <Route
            path="/labestablishment"
            element={<LabEstablishment />}
          />
          <Route
            path="/aircraft-leasing-&-financing"
            element={<ACMI />}
          />
          <Route
            path="/agriculture"
            element={<Agriculture />}
          />
          <Route
            path="/flight-simulator"
            element={<FlightSimulator />}
          />
          <Route
            path="/cessna"
            element={<Cessna />}
          />
          <Route
            path="/airbus"
            element={<Airbus />}
          />
          <Route
            path="/boeing"
            element={<Boeing />}
          />
          <Route
            path="/gallery"
            element={<Gallery />}
          />
          <Route
            path="/pilotgroundinstructor"
            element={<Pilot />}
          />
          <Route
            path="/ameinstructor"
            element={<AME />}
          />
          <Route
            path="/cabincrewinstructor"
            element={<CabinCrewInstructor />}
          />
          <Route
            path="/airportgroundoperationspersonnel"
            element={<GroundStaff />}
          />
          <Route
            path="/flightdispatch"
            element={<FlightDispatch />}
          />
          <Route
            path="/it"
            element={<IT />}
          />
          <Route
            path="/dronepilots"
            element={<DronePilot />}
          />
          <Route
            path="/bdm"
            element={<BDM />}
          />
          <Route
            path="/finance"
            element={<Finance />}
          />
          <Route
            path="/media"
            element={<Media />}
          />
          <Route
            path="/blogs"
            element={<Blogs />}
          />
          <Route
            path="/The-Impact-of-Artificial-Intelligence-on-Aviation"
            element={<Blog1 />}
          />
          <Route
            path="/Exploring-Career-Opportunities-in-Aviation"
            element={<Blog2 />}
          />
          <Route
            path="/A-Day-in-the-Life-of-an-Aviation-Student"
            element={<Blog3 />}
          />
          <Route
            path="/Deciding-Between-Studying-CPL-Abroad-or-in-India"
            element={<Blog4 />}
          />
          <Route
            path="/The-Development-of-Electric-and-hybrid-Aircraft"
            element={<Blog5 />}
          />
          <Route
            path="/Emerging-Technologies-in-Aviation"
            element={<Blog6 />}
          />
          <Route
            path="/Flying-High:-Why-Choosing-the-Aviation-Industry"
            element={<Blog7 />}
          />
          <Route
            path="/Navigating-the-Skies"
            element={<Blog8 />}
          />
          <Route
            path="/Demand-for-Pilots"
            element={<Blog9 />}
          />
          <Route
            path="/How-the-Pandemic-Reshaped-Air-Travel"
            element={<Blog10 />}
          />
          <Route
            path="/The-Future-of-Aviation-Careers"
            element={<Blog11 />}
          />
          <Route
            path="/The-Benefits-of-an-Aviation-Degree"
            element={<Blog12 />}
          />
          <Route
            path="/Your-Journey-to-Become-a-Pilot"
            element={<Blog13 />}
          />
          <Route
            path="/Top-10-Colleges-for-Pursuing-BSc-Aviation-in-India"
            element={<Blog14 />}
          />
          <Route
            path="/The-Future-of-Urban-Air-Mobility"
            element={<Blog15 />}
          />
          <Route
            path="/Zooming-High:-Celebrating-Women-in-Aviation"
            element={<Blog16 />}
          />
          <Route
            path="/Nurturing-Future-Aviators"
            element={<Blog17 />}
          />
          <Route
            path="/Exciting-Internship-Opportunities"
            element={<Blog18 />}
          />
          <Route
            path="/Tips-for-a-Successful-Aviation-Career-Journey"
            element={<Blog19 />}
          />
          <Route
            path="/Big-Data-Taking-Flight"
            element={<Blog20 />}
          />
          <Route
            path="/Air-traffic-management"
            element={<Blog21 />}
          />
          <Route
            path="/The-Impact-of-Cybersecurity-on-Aviation"
            element={<Blog22 />}
          />
          <Route
            path="/Different-Types-of-Aviation-Degrees"
            element={<Blog23 />}
          />
          <Route
            path="/The-Best-Aviation-Schools-and-Programs"
            element={<Blog24 />}
          />
          <Route
            path="/Tamil-Nadu:Your-Launchpad-for-an-Aviation-Career-in-India"
            element={<Blog25 />}
          />
          <Route
            path="/campus"
            element={<Campus />}
          />
          <Route
            path="/dgca-exams-cleared"
            element={<DGCAStudents />}
          />
          <Route
            path="/vels-university"
            element={<VelsLanding />}
          />
          <Route
            path="/crescent-landing"
            element={<CrescentLanding />}
          />
          <Route
            path="/jamal-mohamed-college"
            element={<JamalLanding />}
          />
          <Route
            path="/fixedwing"
            element={<FixedWingElectricAircraft />}
          />
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </div>
      <Footer />

      <div className="fixed bottom-24 right-0 space-y-4 z-50">
        <a
          href="https://www.instagram.com/mh_cockpit?igsh=MTNvMW5wc213MTh0dw=="
          target="_blank"
          rel="noopener noreferrer"
          className="text-pink-500 hover:text-pink-700 transition-colors"
        >
          <FaInstagram size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
        </a>
        <a
          href="https://youtube.com/@mhcockpit?si=8vvLncucf3Lg5szh "
          target="_blank"
          rel="noopener noreferrer"
          className="text-red-600 hover:text-red-700 transition-colors"
        >
          <FaYoutube size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
        </a>
        <a
          href="https://wa.me/9884927480"
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-500 hover:text-green-600 transition-colors"
        >
          <FaWhatsapp size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
        </a>
      </div>
    </div>
  );
}

export default App;
